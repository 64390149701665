import { Box, Text } from '@theme-ui/components';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

interface Props {
  credits: [title: string, name: string][];
}

const Credits = ({ credits }: Props) => {
  return (
    <Fade cascade fraction={0.25}>
      <Box
        as="ul"
        variant="text.ul"
        sx={{
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mb: [3, 3, 5, 5],
        }}
      >
        {credits.map(([title, name]) => (
          <Text
            key={title}
            as="li"
            variant="text.uLi"
            sx={{
              fontSize: [2, 2, 3, 3],
            }}
          >
            <strong>{title}</strong>{name ? `: ${name}` : ''}
          </Text>
        ))}
      </Box>
    </Fade>
  );
};

export default Credits;
