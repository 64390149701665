import breakpoints from './breakpoints';
import buttons from './buttons';
import colors from './colors';
import forms from './forms';
import layout from './layout';
import links from './links';
import sizes from './sizes';
import space from './space';
import typography from './typography';

export default {
  ...typography,
  breakpoints,
  buttons,
  colors,
  forms,
  layout,
  links,
  sizes,
  space,
};
