const forms = {
  label: {
    fontSize: [20, 22, 22, 22],
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'body',
    display: 'block',
    textAlign: 'left',
    letterSpacing: 'heading',
    textTransform: 'uppercase',
    maxWidth: '100%',
    width: 'auto',
  },
  inputBase: {
    backgroundColor: 'darkGray',
    wekKitAppearance: 'none',
    webkitBorderRadius: 'none',
    fontFamily: 'body',
    border: 'none',
    fontSize: [20, 22, 22, 22],
    color: 'secondary',
    px: [2, 2, 3, 3],
    pr: 4,
    pt: 1,
    borderRadius: 0,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: 'ease-out 300ms border',

    '&:focus': {
      borderColor: 'inputBorder',
      outline: 'none',
    },

    '&::placeholder': {
      color: 'placeholder',
    },
  },
  input: {
    variant: 'forms.inputBase',
  },
  textarea: {
    variant: 'forms.inputBase',
    maxWidth: '100%',
  },
  checkbox: {
    width: [24, 24, 36, 36],
    height: [24, 24, 36, 36],
    background: 'transparent',

    'input:focus~&': {
      background: 'none',
    },
  },
};

export default forms;
