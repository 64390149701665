export default {
  fonts: {
    body: 'Oswald, sans-serif',
    heading: 'Oswald, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64],
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    body: '0.14em',
    caps: '1.8em',
  },
  text: {
    heading: {
      textTransform: 'uppercase',
      letterSpacing: 1.5,
    },
    default: {
      color: 'text',
      fontSize: 3,
      fontFamily: 'body',
      fontWeight: 'body',
    },
    siteNavigation: {
      color: 'text',
      fontSize: 3,
      fontFamily: 'body',
      fontWeight: 'body',
      textTransform: 'uppercase',
      letterSpacing: 'body',
      textDecoration: 'none',
      opacity: 0.5,

      '&:hover, &.active': {
        opacity: 1,
      },
    },
    h1: {
      color: 'primary',
    },
    h3: {
      fontSize: [4, 4, 4, 4],
      mb: 3,
    },
    h4: {
      fontSize: [3, 3, 3, 3],
      mb: 3,
    },
    p: {
      mb: 4,
      color: 'text',
      fontSize: [4, 4, 4, 4],
      fontFamily: 'body',
      fontWeight: 'body',
    },
    ul:{
      padding: 0,
    },
    uLi: {
      variant: 'text.p',
      listStyle: 'none',
      mb: 2,
    },
  },
};
