import { lighten } from '@theme-ui/color';

import colors from './colors';

export default {
  primary: {
    background: 'red',
  },
  option: {
    fontSize: 20,
    backgroundColor: 'gray',
    fontFamily: 'body',
    textTransform: 'uppercase',
    fontWeight: 'body',
    cursor: 'pointer',
    borderRadius: 0,
    px: 3,
    transition: 'all ease-in-out 200ms',
    width: ['100%', '100%', 'auto', 'auto'],

    '&:hover': {
      backgroundColor: lighten(colors.gray, 0.1),
    },

    '&.checked': {
      backgroundColor: lighten(colors.gray, 0.2),
    },
  },
  submit: {
    backgroundColor: 'gray',
    fontFamily: 'body',
    textTransform: 'uppercase',
    fontSize: 22,
    px: 4,
    py: 3,
    display: 'block',
    cursor: 'pointer',
    borderRadius: 0,

    '&:hover': {
      backgroundColor: lighten(colors.gray, 0.1),
    },
  },
};
