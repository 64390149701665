import { Heading, Text } from '@theme-ui/components';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

const components = {
  h1: props => <Fade cascade fraction={0.25}><Heading as="h1">{props.children}</Heading></Fade>,
  h2: (props) => <Fade cascade fraction={0.25}><Heading as="h2">{props.children}</Heading></Fade>,
  h3: (props) => <Fade cascade fraction={0.25}><Heading as="h3" variant="text.h3">{props.children}</Heading></Fade>,
  h4: (props) => <Fade cascade fraction={0.25}><Heading as="h4" variant="text.h4">{props.children}</Heading></Fade>,
  p: (props) => <Fade cascade fraction={0.25}><Text as="p" variant="text.p">{props.children}</Text></Fade>,
};

export default components;